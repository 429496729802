import { useEffect, useState } from "react";

import {
    Card,
    Col,
    Row,
    Typography,
    Tooltip,
    Button
} from "antd";
import {
    MenuUnfoldOutlined,
    MoreOutlined,
    RiseOutlined,
    FallOutlined,
    ClockCircleOutlined,
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import Echart from "../../components/chart/EChart";
import LoadingBase from "../../components/LoadingBase";
import { formatNumber } from "../../utils/funcHelper";
import { Images } from "../../assets";
import moment from "moment";
import { DATE_FORMAT } from "../../constants/constants";
import { useSelector } from "react-redux";
import { consoleLogUtil } from "../../utils/consoleLogUtil";
import { BASE_URL } from "../../settings/config";
import dayjs from "dayjs";

const DATA_DEFAULT = [
    {
        today: 100,
        title: "TỔNG XE GỬI",
        persent: 10,
        icon: Images.ic_car_pos,
        bnb: "bnb2",
        classBox: 'icon-box',
        toolTip: 'Số xe gửi trong ngày'
    },
    {
        today: 60,
        title: "XE ĐANG GỬI",
        persent: 0,
        icon: Images.ic_car_parking,
        bnb: "bnb2",
        classBox: 'icon-box',
        toolTip: 'Số xe đang gửi'
    },
    {
        today: 40,
        title: "XE RA",
        persent: 0,
        icon: Images.ic_car_complete,
        bnb: "bnb2",
        classBox: 'icon-box',
        toolTip: 'Số xe đã lấy'
    },
    {
        today: "2.500.000",
        title: "TỔNG TIỀN THU",
        persent: 0,
        icon: Images.ic_dollar,
        bnb: "redtext",
        classBox: 'icon-box',
        toolTip: 'Tổng tiền thu trong ngày'
    },

];

const LST_COMPLETE = [
    {
        id: 1,
        license: "30H-838.38",
        time: "3h",
        total: "60.000đ"
    },
    {
        id: 2,
        license: "20H-553.38",
        time: "2h",
        total: "40.000đ"
    },
    {
        id: 3,
        license: "29G-828.18",
        time: "5h",
        total: "100.000đ"
    },
    {
        id: 4,
        license: "30H-838.38",
        time: "3h",
        total: "60.000đ"
    },
    {
        id: 5,
        license: "30F-223.33",
        time: "3.5h",
        total: "70.000đ"
    }
]

const LST_RECENT =[
    {
        id: 1,
        license: "30H-838.38",
        type: 'Ô tô',
        start_time: "10:00:00",
        total: "20.000đ",
        date: moment().format("DD-MM-YYYY")
    },
    {
        id: 2,
        license: "22H-321.99",
        type: 'Ô tô',
        start_time: "10:10:00",
        total: "20.000đ",
        date: moment().format("DD-MM-YYYY")
    },
    {
        id: 3,
        license: "29H-999.33",
        type: 'Ô tô',
        start_time: "10:20:00",
        total: "20.000đ",
        date: moment().format("DD-MM-YYYY")
    },
    {
        id: 4,
        license: "20H-838.30",
        type: 'Ô tô',
        start_time: "12:00:00",
        total: "20.000đ",
        date: moment().format("DD-MM-YYYY")
    },
    {
        id: 5,
        license: "88H-555.66",
        type: 'Ô tô',
        start_time: "12:20:00",
        total: "20.000đ",
        date: moment().format("DD-MM-YYYY")
    },
]
const LST_INCOME = [
    {
        id: 1,
        total: "30.000.000đ",
        date: "30-06-2024"
    },
    {
        id: 2,
        total: "25.000.000đ",
        date: "29-06-2024"
    },
    {
        id: 3,
        total: "32.000.000đ",
        date: "28-06-2024"
    },
    {
        id: 4,
        total: "15.000.000đ",
        date: "27-06-2024"
    },
    {
        id: 5,
        total: "20.000.000đ",
        date: "26-06-2024"
    },
    
]
const DATA_CHART = [
    {
        id: 1,
        total: 100,
        date: "30-06-2024"
    },
    {
        id: 2,
        total: 200,
        date: "29-06-2024"
    },
    {
        id: 3,
        total: 120,
        date: "28-06-2024"
    },
    {
        id: 4,
        total: 90,
        date: "27-06-2024"
    },
    {
        id: 5,
        total: 210,
        date: "26-06-2024"
    },
    
]
const DashboardPage = () => {
    const userReducer = useSelector(state => state?.userReducer);
    const { Title, Text } = Typography;
    const [reverse, setReverse] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataTop, setDataTop] = useState(DATA_DEFAULT);
    const [dataChart, setDataChart] = useState(DATA_CHART);
    const [lstComplete, setLstComplete] = useState(LST_COMPLETE);
    const [lstRegister, setLstRegister] = useState(LST_RECENT);
    const [lstSurveyRecent, setLstSurveyRecent] = useState(LST_INCOME);

    const caculatorPersent = (numb1, numb2) => {
        let result = 0;
        if (numb2 > 0) {
            result = (numb1 - numb2)*100/numb2;
        } else {
            if (numb1 > 0) {
                result = 100
            } else {
                result = 0
            }
        }
        return result;
    }
    const getDataSurveyRecent = async () => {
        
    }
    const getDataRegisRecent = async () => {
        
    }
    const getDataCompleteRecent = async () => {
        
    }
    const getDataForChart = async () => {
        
    }
    const getDataSurveyComplete = async () => {
        
    }
    const getDataShift = async () => {
        
    }

    useEffect(() => {
        
    }, [])

    return (
        <>
            <div className="layout-content">
                <Row className="rowgap-vbox" gutter={[24, 0]}>
                    {dataTop.map((c, index) => (
                        <Col
                            key={index}
                            xs={24}
                            sm={24}
                            md={12}
                            lg={6}
                            xl={6}
                            className="mb-24"
                        >
                            <Card bordered={false} className="criclebox ">
                                <div className="number">
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col xs={18}>
                                            <span>{c.title}</span>

                                            <Tooltip placement="top" title={c.toolTip}>
                                                <Title level={3}>
                                                    {c.today} {index < 2 ? <small className="txt-unit">{index === 0 ? "Xe" : "Xe"}</small> : null}
                                                </Title>
                                            </Tooltip>


                                        </Col>
                                        <Col xs={6} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                            <Tooltip placement="top" title={'Tăng/giảm so với hôm qua'}>
                                                <div className="box-icon-chart">
                                                    {c.persent > 0 ? <RiseOutlined style={{ color: '#52c41a' }} /> : (c.persent < 0 ? <FallOutlined style={{ color: 'red' }} /> : null)}
                                                    <small className={c.bnb}>{`${formatNumber(c.persent, 0)}%`}</small>
                                                </div>

                                            </Tooltip>
                                            <div className={c.classBox}><img src={c.icon} className="img-db" /></div>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>

                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={12} md={212} lg={12} xl={12} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <Echart options={{
                                xaxis: {
                                    categories: dataChart?.map(item => moment(item.date, DATE_FORMAT.DD_MM_YYYY_FORMAT).format(DATE_FORMAT.DDMM_FORMAT))
                                }
                            }} series={[
                                {
                                    name: "Số lượt gửi",
                                    data: dataChart?.map(item => item.total),
                                    color: "#fff",
                                },
                            ]} />
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <div className="project-ant">
                                <div>
                                    <Title level={5}>Danh sách xe thanh toán gần đây</Title>
                                </div>

                            </div>
                            <div className="ant-list-box table-responsive">
                                <table className="width-100">
                                    <thead>
                                        <tr>
                                            <th>Biển số</th>
                                            <th>Thời gian gửi</th>
                                            <th>Tổng tiền</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {lstComplete.map((d, index) => (
                                            <tr key={index}>
                                                <td>
                                                <div style={{ justifyItems: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
                                                        <img className="tootip-img" src={d?.patientAvatar?.length > 0 ? (BASE_URL + d?.patientAvatar) : Images.ic_car_parking} alt="" />
                                                        <a><p style={{ fontSize: 13, fontWeight: 'bold', paddingLeft: 12 }}>{d?.license ?? ''}</p></a>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="text-xs font-weight-bold">
                                                        {d?.time}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-xs font-weight-bold">
                                                        {d?.total}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Card>

                    </Col>
                </Row>

                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
                        <Card bordered={false} className="criclebox cardbody h-full">
                            <div className="project-ant">
                                <div>
                                    <Title level={5}>Danh sách xe đang gửi</Title>
                                    <Paragraph className="lastweek">
                                        Xe gửi hôm nay:<span className="blue" style={{ fontSize: 18 }}>{60}</span>
                                    </Paragraph>
                                </div>

                            </div>
                            <div className="ant-list-box table-responsive">
                                <table className="width-100">
                                    <thead>
                                        <tr>
                                            <th>Biển số</th>
                                            <th>Loại xe</th>
                                            <th>Thời gian vào</th>
                                            <th>Tạm tính</th>
                                            <th>Ngày</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {lstRegister.map((d, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div style={{ justifyItems: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
                                                        <img className="tootip-img" src={d?.avatar?.length > 0 ? (BASE_URL + d?.avatar) : Images.ic_car_parking} alt="" />
                                                        <a><p style={{ fontSize: 13, fontWeight: 'bold', paddingLeft: 12 }}>{d?.license ?? ''}</p></a>
                                                    </div>
                                                </td>
                                                <td>{d?.type ?? ''}</td>
                                                <td>
                                                    <span className="text-xs font-weight-bold">
                                                        {d.start_time ?? ''}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-xs font-weight-bold">
                                                        {d?.total ?? ''}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-xs fon-eight-bold">
                                                        {d?.date}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="uploadfile shadow-none">
                                <Button
                                    type="dashed"
                                    className="ant-full-box"
                                    icon={<MoreOutlined />}
                                >
                                    <span className="click">Xem thêm</span>
                                </Button>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <div className="timeline-box">
                                <Title level={5}>Thu nhập theo ngày</Title>
                                <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
                                </Paragraph>

                                {lstSurveyRecent.map((item, index) => (
                                    <Row style={{ marginBottom: 12 }}>
                                        <Col span={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <img src={item?.avatar?.length > 0 ? (BASE_URL + item?.avatar) : Images.ic_dates} style={{ width: 16, height: 16 }} />
                                        </Col>
                                        <Col span={10}>
                                            <p>{item?.date ?? ''}</p>
                                        </Col>
                                        <Col span={10}>
                                            <strong>{item?.total ?? ''}</strong>
                                        </Col>
                                    </Row>

                                ))}
                                <Button
                                    type="primary"
                                    className="width-100 btn-more"
                                    onClick={() => setReverse(!reverse)}
                                >
                                    {<MenuUnfoldOutlined />} Xem thêm
                                </Button>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <LoadingBase loading={loading} />
            </div>
        </>
    );
}

export default DashboardPage;
