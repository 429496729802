import { Button, Input, message, Modal } from "antd"
import React, { useEffect, useState } from "react";

const ModalEdit = ({ visible, onCancel, onSave, data }) => {
    const [name, setName] = useState('');
    
    const handleSave = () => {
        if (name.length > 0) {
            const temp = {
                ...data,
                name: name
            }
            if (name == data?.name) {
                return onSave(temp, false); // false là không cần gọi api để update do tên không đổi
            } else {
                return onSave(temp, true);
            }
            
        }
        message.warning("Vui lòng nhập tên");
    };

    useEffect(() => {
        if (data)
            setName(data?.name);
    }, [data])

    return (
        <Modal
            title={"Sửa tên"}
            open={visible}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Hủy
                </Button>,
                <Button key="save" type="primary" onClick={handleSave}>
                    Lưu
                </Button>,
            ]}
        >
            <p>Tên người gửi</p>
            <Input
                placeholder="Nhập tên người gửi"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <p className="label-modal">Địa chỉ</p>
            <Input
                value={data?.email}
                disabled={true}
            />
        </Modal>
    )
}
export default React.memo(ModalEdit);