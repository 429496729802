import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import LoginPage from './screens/login/LoginPage';
import NotFound from './components/NotFound';
import DashboardPage from './screens/dashboard/DashboardPage';
import PrivateRoute from './components/PrivateRoute';
import ListDepartmentPage from './screens/category/company/ListDepartmentPage';
import ListClassPage from './screens/category/prices/ListClassPage';
import ListAccountPage from './screens/mana-account/ListAccountPage';
import SendEmailPage from './screens/send/SendEmailPage';
import ReportEmailPage from './screens/report/ReportEmailPage';
import ReportStatusSendPage from './screens/report/ReportStatusSendPage';
import ChangePassPage from './screens/changepass/ChangePassPage';


const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='*' element={<NotFound />} />
                <Route path="/dang-nhap" element={
                    <LoginPage />
                } />
                <Route path="/" element={
                    <PrivateRoute>
                        <SendEmailPage />
                    </PrivateRoute>
                } />
                <Route path="/send-email" element={
                    <PrivateRoute>
                        <SendEmailPage />
                    </PrivateRoute>
                } />
                <Route path="/bao-cao-email" element={
                    <PrivateRoute>
                        <ReportEmailPage />
                    </PrivateRoute>
                } />
                <Route path="/kiem-tra-trang-thai-gui" element={
                    <PrivateRoute>
                        <ReportStatusSendPage />
                    </PrivateRoute>
                } />
                {/* <Route path="/dashboard" element={
                    <PrivateRoute>
                        <DashboardPage />
                    </PrivateRoute>
                } /> */}
                
                <Route path="/quan-ly-tai-khoan" element={
                    <PrivateRoute>
                        <ListAccountPage />
                    </PrivateRoute>
                } />
                <Route path="/doi-mat-khau" element={
                    <PrivateRoute>
                        <ChangePassPage />
                    </PrivateRoute>
                } />
                
            </Routes>

        </BrowserRouter>
    );
};

export default AppRouter;