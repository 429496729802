import axiosClient from "./axios.client";

export const login = (params) => {
    return axiosClient.post('authen/login', params);
}

export const saveLogUser = (params) => {
    return axiosClient.post('logUser',params);
}
export const getListDeparment = (params) => {
    return axiosClient.post(`cms/category/getListDepartment`, params);
}

export const addNewDepartment = (params) => {
    return axiosClient.post('cms/category/addNewDepartment', params);
}

export const updateDepartmentById = (params) => {
    return axiosClient.post('cms/category/updateDepartmentById', params);
}

export const getListClass = (params) => {
    return axiosClient.get(`cms/category/getListClass?keyword=${params?.keyword}&limit=${params?.limit}&currentPage=${params?.currentPage}`);
}

export const addNewClass = (params) => {
    return axiosClient.post('cms/category/addNewClass', params);
}

export const updateClassById = (params) => {
    return axiosClient.post('cms/category/updateClass', params);
}

export const getListAccount = (params) => {
    return axiosClient.post('cms/account/getListAccount', params);
}

// API sendemail

export const addListEmailReceiver = (params) => {
    return axiosClient.post('cms/account/insertListEmailReceiver', params);
}

export const saveLogActionSend = (params) => {
    return axiosClient.post('cms/log/saveLogActionSend', params);
}

export const getReportEmail = (params) => {
    return axiosClient.post('cms/report/getReportEmail', params);
}

export const doSendEmail = (params) => {
    return axiosClient.post('cms/send/sendEmail', params);
}

export const getRemainingEmails = (params) => {
    return axiosClient.post('cms/report/getRemainingEmails', params);
}

export const getConfigLimitByUser = (params) => {
    return axiosClient.post('cms/config/getConfigLimitByUser', params);
}

export const getStatusSendEmail = (params) => {
    return axiosClient.post('cms/report/getStatusSendEmail', params);
}

export const updateNameSender = (params) => {
    return axiosClient.post('cms/config/updateNameSender', params);
}

export const changeUserPassword = (param) => {
    return axiosClient.post('authen/changePassword', param);
}