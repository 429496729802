import { Button, Col, Form, Row, Space, theme, Table, DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { consoleLogUtil } from '../../utils/consoleLogUtil';
import {  getReportEmail } from '../../services/apis';
import FooterTable from '../../components/FooterTable';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

const ReportEmailPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const { RangePicker } = DatePicker;
    const [totalRecords, setTotalRecords] = useState(0);
    const userReducer = useSelector(state => state?.userReducer);
    const columns = [
        {
            title: 'STT',
            width: 50,
            align: 'center',
            render: (text, record, index) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{index + 1}</div>
        },
        {
            title: 'Email gửi',
            dataIndex: 'email_send',
            key: 'email_send',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Email nhận',
            dataIndex: 'email_receiver',
            key: 'email_receiver',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Tiêu đề',
            dataIndex: 'subject',
            key: 'subject',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            align: 'center',
            render: (text) => <div style={text === 1 ? { color: 'blue' } : null}>{text === 1 ? "Thành công" : "Thất bại"}</div>
        },
        {
            title: 'Thời gian',
            dataIndex: 'createAt',
            align: 'createAt',
        },

    ];

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    const getDataReport = async (page, startTime, endTime) => {
        const params = {
            startTime: startTime,
            endTime: endTime,
            limit: 50,
            currentPage: page,
            userId: userReducer?.data?.user_info?.id
        };
        setLoading(true);
        const res = await getReportEmail(params);
        setLoading(false);
        if (res && res?.status === 1) {
            setData(res.data?.data ? [...res.data?.data] : []);
            setTotalPage(res?.data?.totalPages);
            setTotalRecords(res?.data?.totalRecords)
        } else {
            setData([])
            setTotalPage(0);
            setTotalRecords(0);
        }
    }


    const onFinish = async (dataForm) => {
        setCurrentPage(1);
        if (dataForm && dataForm?.date) {
            getDataReport(1, dayjs(dataForm.date[0]).format("YYYY-MM-DD HH:mm:ss"), dayjs(dataForm.date[1]).format("YYYY-MM-DD HH:mm:ss"))
        } else {
            getDataReport(1, dayjs().format("YYYY-MM-DD") + "00:00:00", dayjs().format("YYYY-MM-DD HH:mm:ss"))
        }
    };
    const handlePrev = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page - 1);
        getDataReport(page - 1, dayjs(form.getFieldValue("date")[0].format("YYYY-MM-DD HH:mm:ss")), dayjs(form.getFieldValue("date")[1].format("YYYY-MM-DD HH:mm:ss")));
    }

    const handleOnSelect = (value) => {
        setCurrentPage(value);
        getDataReport(value, dayjs(form.getFieldValue("date")[0]).format("YYYY-MM-DD HH:mm:ss"), dayjs(form.getFieldValue("date")[1]).format("YYYY-MM-DD HH:mm:ss"))
    }
    const handleNext = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page + 1);
        getDataReport(page + 1, dayjs(form.getFieldValue("date")[0]).format("YYYY-MM-DD HH:mm:ss"), dayjs(form.getFieldValue("date")[1]).format("YYYY-MM-DD HH:mm:ss"));
    }

    useEffect(() => {
        const now = dayjs();
        form.setFieldsValue({
            date: [now.startOf('day'), now]
        });
        getDataReport(1, now.startOf('day').format("YYYY-MM-DD HH:mm:ss"), now.format("YYYY-MM-DD HH:mm:ss"));
    }, [form]);

    return (
        <div className='container-content'>

            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={10}>
                        <Form.Item
                            name={'date'}
                        >
                            <RangePicker
                                style={{height: 40, width: '100%'}}
                                format="YYYY-MM-DD HH:mm:ss"
                                showTime
                                allowClear={false}
                                />
                        </Form.Item>
                    </Col>


                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record?.id}
            />
            <FooterTable
                totalRecord={totalRecords}
                totalPage={totalPage}
                currentPage={currentPage}
                handleNext={handleNext}
                handlePrev={handlePrev}
                onSelect={handleOnSelect}
            >
            </FooterTable>
            <LoadingBase loading={loading} />

        </div>
    )
}
export default React.memo(ReportEmailPage);