import {
    LogoutOutlined,
    MenuFoldOutlined,
    FileSearchOutlined,
    ExceptionOutlined,
    TeamOutlined,
    BarChartOutlined,
    TagOutlined,
    BookOutlined,
    ProjectOutlined,
    MenuOutlined,
    SendOutlined,
    DashboardOutlined,
    ReloadOutlined
} from '@ant-design/icons';

export const DATE_FORMAT = {
    DDMMYYYY_FORMAT: "DD/MM/YYYY",
    YYYYMMDD_FORMAT: "YYYY-MM-DD",
    DDMM_FORMAT: "DD/MM",
    DD_MM_YYYY_FORMAT: "DD-MM-YYYY"
}

export const TYPE_MODAL = {
    ADD: "ADD",
    EDIT: "EDIT",
    DEL: "DEL",
    APPROVE: "APPROVE"
}

export const LIST_MENU_SIDE_BAR = [
    // {
    //     key: 'dashboard',
    //     path: "/dashboard",
    //     label: 'Dashboard',
    //     icon: <BarChartOutlined />,
    //     permission: [1, 2, 3, 4]
    // },
    {
        key: 'send-email',
        path: "/send-email",
        label: 'Gửi email',
        icon: <SendOutlined />,
        permission: [1, 2, 3, 4]
    },
    // {
    //     label: 'Quản lý vé',
    //     icon: <BookOutlined />,
    //     children: [
    //         {
    //             key: 'danh-sach-ve',
    //             path: "/danh-sach-ve",
    //             label: 'Danh sách vé',
    //             icon: <MenuOutlined />,
    //             permission: [1, 2, 3, 4]
    //         },
    //         {
    //             key: 'xuat-hoa-don',
    //             path: "/xuat-hoa-don",
    //             label: 'Xuất hoá đơn',
    //             icon: <TagOutlined />,
    //             permission: [1]
    //         }
    //     ]
    // },
    
    {
        label: 'Báo cáo',
        icon: <ProjectOutlined />,
        children: [
            {
                key: 'bao-cao-email',
                path: "/bao-cao-email",
                label: 'Báo cáo email gửi',
                icon: <FileSearchOutlined />,
                permission: [1, 2, 3, 4]
            },
            {
                key: 'kiem-tra-trang-thai-gui',
                path: "/kiem-tra-trang-thai-gui",
                label: 'Kiểm tra trạng thái gửi',
                icon: <DashboardOutlined />,
                permission: [1, 2, 3, 4]
            },
        ]
    },
    // {
    //     label: 'Danh mục',
    //     icon: <MenuFoldOutlined />,
    //     children: [
    //         {
    //             key: 'danh-sach-diem',
    //             path: "/danh-sach-diem",
    //             label: 'Danh sách điểm',
    //             icon: <FileSearchOutlined />,
    //             permission: [1]
    //         },
    //         {
    //             key: 'gia-dich-vu',
    //             path: "/gia-dich-vu",
    //             label: 'Giá dịch vụ',
    //             icon: <ExceptionOutlined />,
    //             permission: [1]
    //         }
    //     ]
    // },
    {
        key: 'quan-ly-tai-khoan',
        path: "/quan-ly-tai-khoan",
        label: 'Quản lý email gửi',
        icon: <TeamOutlined />,
        permission: [1]
    },
    {
        key: 'doi-mat-khau',
        path: "/doi-mat-khau",
        label: 'Đổi mật khẩu',
        icon: <ReloadOutlined />,
        permission: [1]
    },
    {
        key: 'dang-xuat',
        path: "/dang-xuat",
        label: 'Đăng xuất',
        icon: <LogoutOutlined />,
        permission: [1, 2, 3, 4]
    },

];

export const requiredItem = [
    {
        required: true,
        message: 'Vui lòng không để trống'
    }
];

export const SEX_VALUE = [
    {
        label: "Nam",
        value: "1", 
    },
    {
        label: "Nữ",
        value: "0", 
    }
];

export const TYPE_USER = [
    {
        label: "Quản trị viên",
        value: "0", 
    },
    {
        label: "Học sinh",
        value: "1", 
    },
    {
        label: "Chuyên gia",
        value: "2", 
    }
];