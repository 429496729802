import { Button, Col, Form, Input, Row, Space, theme, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { useSelector } from 'react-redux';
import { getRemainingEmails, updateNameSender } from '../../services/apis';
import ModalEdit from './component/ModalEdit';

const ListAccountPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataView, setDataView] = useState([]);
    const userReducer = useSelector(state => state?.userReducer);
    const [showModal, setShowModal] = useState(false);
    const [itemSelected, setItemSelected] = useState();
    const columns = [
        {
            title: 'STT',
            width: 50,
            align: 'center',
            render: (text, record, index) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{index + 1}</div>
        },
        {
            title: 'Họ tên gửi',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Email gửi',
            dataIndex: 'email',
            key: 'email',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        // {
        //     title: 'Mật khẩu',
        //     dataIndex: 'pass',
        //     key: 'pass',
        //     render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        // },
        {
            title: 'Số email đã gửi',
            dataIndex: 'totalSent',
            align: 'center',
        },
        // {
        //     title: 'Max có thể gửi',
        //     dataIndex: 'totalMax',
        //     align: 'center',
        // },
        {
            title: 'Hành động',
            align: 'center',
            render: (text, record, index) => <a style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }} href='#' onClick={() => handleEditName(record)}>Sửa</a>
        },
    ];

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const handleEditName = (item) => {
        setItemSelected(item);
        setShowModal(true);
    }
    const handleOnSave = async (item, isUpdate) => {
        setShowModal(false);
        if (isUpdate) {
            try {
                setLoading(true);
                const params = {
                    newName: item.name,
                    email: item.email,
                    userId: userReducer?.data?.user_info?.id
                }
                const res = await updateNameSender(params);
                setLoading(false);
                if (res && res.status === 1) {
                    message.success("Cập nhật thành công");
                    getDataEmails();
                } else {
                    message.warning("Cập nhật thất bại. Vui lòng thử lại");
                }
            } catch (error) {
                setLoading(false);
                message.warning("Cập nhật thất bại. Vui lòng thử lại");
            }

        }
    }
    const getDataEmails = async () => {
        try {
            const params = {
                userId: userReducer?.data?.user_info?.id
            }
            setLoading(true);
            const res = await getRemainingEmails(params);
            setLoading(false);
            if (res && res?.status === "success") {
                setData(res?.dataEmail ? [...res?.dataEmail] : []);
                setDataView(res?.dataEmail ? [...res?.dataEmail] : []);
            } else {
                setData([]);
                setDataView([])
            }
        } catch (error) {
            setLoading(false);
            setData([]);
            setDataView([])
            message.warning("Có lỗi xảy ra vui lòng tải lại");
        }

    }


    const onFinish = async (dataForm) => {
        // getDataUsers(dataForm?.name);
        if (dataForm?.name?.length > 0) {
            const temp = data.filter(item => item?.name?.toLowerCase().includes(dataForm?.name?.toLowerCase()) || item?.email?.toLowerCase().includes(dataForm?.name?.toLowerCase()));
            setDataView([...temp]);
        } else {
            setDataView([...data]);
        }
    };


    useEffect(() => {
        getDataEmails();
    }, [])

    return (
        <div className='container-content'>

            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'name'}
                        >
                            <Input placeholder={'Nhập email'} />
                        </Form.Item>
                    </Col>


                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={dataView}
                pagination={false}
                rowKey={(record) => record?.email}
            />
            <LoadingBase loading={loading} />
            <ModalEdit
                visible={showModal}
                onCancel={() => setShowModal(false)}
                data={itemSelected}
                onSave={handleOnSave}
            />
        </div>
    )
}
export default React.memo(ListAccountPage);